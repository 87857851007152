<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible" width="370px" :close-on-click-modal="false" @close="close">
            <div class="title" v-if="type=='1'">AI漫画制作中，预计生成时间1分钟</div>
            <div class="title" v-else>AI绘画制作中</div>
            <div class="loading">
                <img src="@/assets/images/workben/loading.png" alt="">
            </div>
            <div class="text" v-if="type=='1'">AI漫画支持后台生成，关闭窗口不影响图片生成</div>
            <div class="text" v-else>Ai绘画支持后台生成，可以在<span>绘画作品</span>中查看</div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    //1漫画  2绘画
    props: {
        type: {
            type: String,
            default: '2',
        }
    },
    data() {
        return {
            dialogVisible: false,
            timer: null,
        };
    },
    created() {

    },
    methods: {
        open() {
            this.dialogVisible = true
            // this.setTime()
        },
        setTime() {
            var that = this
            that.timer = setTimeout(function () {
                if (that.dialogVisible) {
                    if (that.type == '1') {
                        that.$emit('comicsParents', '')
                    } else {
                        that.$emit('paintingParents', '')
                    }
                    that.dialogVisible = false
                }
            }, 3000)
        },
        close() {
            // clearTimeout(this.timer)
            // this.timer = null
            this.dialogVisible = false

        },
    },
};
</script>

<style scoped lang="scss">
.title {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    text-align: center;
    margin-top: -20px;
}
.text {
    width: 100%;
    padding-top: 28px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    text-align: center;
    span {
        color: #2e4bf2;
        cursor: pointer;
    }
}
.loading {
    width: 54px;
    height: 54px;
    margin: 26px auto 0;
    animation: infinite-loop 1s infinite ease-in-out;
    img {
        width: 100%;
        height: 100%;
    }
}
@keyframes infinite-loop {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
::v-deep .el-dialog {
    margin-top: 25vh !important;
    border-radius: 10px;
}
</style>
