export const mixinIndex = {
  data() {
    return {
      // 登录验证
      siginRules:{
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不对', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      // 注册
      regsterRules:{
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不对', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        invCode:[
          { required: true, message: '请输入邀请码', trigger: 'blur' },
        ]
      },
      // 充值切换nav
      recharge_list:[
        {name:'通用视频时长充值',type:'4'},
        {name:'音频时长充值',type:'11'},
        {name:'购AI绘画次数',type:'9'},
        {name:'购AI漫画次数',type:'12'},
        {name:'钻石充值',type:'15'},
      ],
      // 您还没有绘画次数
      draw_list:[
        {image:require('@/assets/images/icon/draw_icon1.png'),name:'会员专属数字人',title:'数字人使用权',tip:'会员专属数字人'},
        {image:require('@/assets/images/icon/draw_icon2.png'),name:'会员专属声音',title:'',tip:''},
        {image:require('@/assets/images/icon/draw_icon3.png'),name:'视频时长包',title:'视频制作时长',tip:'360分钟/年'},
        {image:require('@/assets/images/icon/draw_icon4.png'),name:'绘画次数包',title:'绘画生成次数',tip:'6000次/年'},
        {image:require('@/assets/images/icon/draw_icon5.png'),name:'音频时长包',title:'音频制作时长',tip:'600分钟/年'},
        {image:require('@/assets/images/icon/draw_icon6.png'),name:'漫画次数包',title:'漫画生成次数',tip:'2400次/年'},
      ],
      // 开通会员
      member_list:[
        {
          name:'月度会员',
          price:'298',
          money:'398',
          time:'9.93',
          childer:[
            {text:'会员VIP专属数字人',tip:''},
            {text:'会员VIP专属声音',tip:''},
            {text:'30分钟 视频制作时长/月',tip:'(有效期30天)'},
            {text:'500次 绘画生成次数/月',tip:'（长期有效）'},
            {text:'50分钟 音频制作时长/月',tip:'（长期有效）'},
            {text:'200次 AI漫画生成次数/月',tip:'（长期有效）'},
          ]
        },
        {
          name:'季度会员',
          price:'698',
          money:'1194',
          time:'7.76',
          childer:[
            {text:'会员VIP专属数字人',tip:''},
            {text:'会员VIP专属声音',tip:''},
            {text:'90分钟 视频制作时长/月',tip:'(有效期90天)'},
            {text:'1500次 绘画生成次数/月',tip:'（长期有效）'},
            {text:'150分钟 音频制作时长/月',tip:'（长期有效）'},
            {text:'600次 AI漫画生成次数/月',tip:'（长期有效）'},
          ]
        },
        {
          name:'年度会员',
          price:'2380',
          money:'4776',
          time:'6.52',
          childer:[
            {text:'会员VIP专属数字人',tip:''},
            {text:'会员VIP专属声音',tip:''},
            {text:'360分钟 视频制作时长/月',tip:'(有效期365天)'},
            {text:'6000次 绘画生成次数/月',tip:'（长期有效）'},
            {text:'600分钟 音频制作时长/月',tip:'（长期有效）'},
            {text:'2400次 AI漫画生成次数/月',tip:'（长期有效）'},
          ]
        }
      ],
    }
  },
  computed: {

  },
  created() {},
  mounted() {},
  methods: {},
}
