<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div>
            <el-dialog class="invest_money zc_dialog_inv" :visible.sync="dialogVisible" width="720px" top="10vh" :close-on-click-modal='false' :append-to-body="true">
                <div class="inv_title">
                    <div class="title">充值</div>
                    <div class="nav">
                        <span :class="inv_index == index ? 'span_active' : ''" v-for="(item, index) in recharge_list" :key="index"
                            @click="navBtn(index)">{{ item.name }}</span>
                    </div>
                </div>
                <div class="inv_content" v-loading='loading'>
                    <div class="surplus" v-if="inv_index == 0 || inv_index == 1">剩余时长：<span>{{ balance || '0秒' }}</span></div>
                    <div class="surplus" v-if="inv_index == 2 || inv_index == 3">剩余次数：<span>{{ balance || '0' }}次</span></div>
                    <div class="surplus" v-if="inv_index == 4">钻石余额：<img src="@/assets/images/icon/diamond_icon.png"
                            alt=""><span>{{ diamond || 0 }}</span></div>
                    <div class="money_list">
                        <div class="list" :class="money_index == index ? 'list_active' : ''" v-for="(item, index) in moneyList"
                            :key="index" @click="moneyBtn(index)">
                            <div class="list_top">
                                <span class="time" v-if="inv_index == 4">{{ item.diamond_number }}</span>
                                <span class="time" v-else>{{ item.earn_number }}</span>
                                <img v-if="inv_index == 4" src="@/assets/images/icon/diamond_icon.png" alt="">
                                <span v-else class="type">{{ item.earn_unit_text }}</span>
                            </div>
                            <div class="list_bot">
                                <img v-if="inv_index !== 4" src="@/assets/images/icon/diamond_icon.png" alt="">
                                <span v-if="inv_index == 4">￥{{ item.earn_number }}</span>
                                <span v-else>{{ item.diamond_number }}</span>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="pay_btn">
                        <div class="left">
                            <div class="left_title" v-if="inv_index !== 4">
                                <span class="title">钻石余额：</span>
                                <img src="@/assets/images/icon/diamond_icon.png" alt="">
                                <span class="price">{{ diamond || 0 }}</span>
                                <span class="tip" v-if="butDisabled">（钻石余额不足，无法完成支付，建议前往充值）</span>
                            </div>
                            <div class="left_tip" :class="inv_index == 4 ? 'left_tip_four' : ''">
                                <span>1.您购买的商品为虚拟服务，购买后不支持退订、转让、退换；</span>
                                <span v-if="inv_index == 1">2.您购买的时长只会在合成音频时消耗，视频合成不消耗合成时长；</span>
                                <span>{{ inv_index == 1 ? 3 : 2 }}.购买后，可在我的订单中查看。</span>
                            </div>
                        </div>
                        <div class="right">
                            <el-button :disabled='butDisabled' class="btnBgColor_blue" size="small" type="primary"
                                @click="affirmBtn">立即购买</el-button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 支付 -->
        <pay-page ref='dialogPage' @renovate='renovateBtn'></pay-page>
        <!-- 钻石支付 -->
        <pay-diamond ref='dialogdiamond' @renovate='renovateBtn'></pay-diamond>
    </div>
</template>

<script>
import {
    mixinIndex
} from "@/mixin/index"
import PayPage from '@/components/pay_page/pay_page.vue'//支付
import PayDiamond from '@/components/pay_page/pay_diamond.vue'//钻石支付
export default {
    mixins: [mixinIndex],
    components: { PayPage, PayDiamond },
    props: {
        type: {
            type: String,
            defaul: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            // 剩余数据
            balance: '',
            diamond: '',
            // 切换选择
            inv_index: 0,
            // 充值数据
            moneyList: [],
            // 选择充值
            money_index: 1,
        }
    },
    computed: {
        butDisabled() {
            if (this.inv_index == 4) {
                return false
            } else {
                if (this.moneyList.length) {
                    if (this.diamond < this.moneyList[this.money_index].diamond_number) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(type = 0) {
            this.inv_index = type
            this.dialogVisible = true
            this.money_index = 0
            this.rechangeInfo()
        },
        // 获取充值数据
        rechangeInfo() {
            var that = this
            that.loading = true
            var params = {
                type: that.recharge_list[that.inv_index].type
            }
            that.$payApi.getImmediately(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.moneyList = res.result.data
                    that.balance = res.result.balance
                    that.diamond = res.result.diamond
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 切换
        navBtn(index) {
            this.money_index = 0
            this.inv_index = index
            this.rechangeInfo()
        },
        // 选择
        moneyBtn(index) {
            this.money_index = index
        },
        // 立即购买
        affirmBtn() {
            if (this.inv_index == 4) {
                // 充值钻石
                var obj = this.moneyList[this.money_index]
                this.$refs.dialogPage.openDialogBtn(obj)
            } else {
                // 钻石支付
                var obj = this.moneyList[this.money_index]
                this.$refs.dialogdiamond.openDialogBtn(obj, 1)
            }
        },
        // 钻石支付关闭刷新
        renovateBtn() {
            this.rechangeInfo()
            this.$store.dispatch('userInfo/getUserInfo')
        },
    },
}
</script>

<style lang='scss' scoped>
.invest_money {
    ::v-deep .el-dialog {
        border-radius: 20px;
        background: none;
    }

    .inv_title {
        height: 116px;
        border-radius: 20px 20px 0 0;
        background: url(~@/assets/images/background/recharge_img.jpg) no-repeat 100% 100%;

        .title {
            line-height: 69px;
            font-size: 18px;
            text-align: center;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }

        .nav {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;

            span {
                cursor: pointer;
                display: block;
                width: 20%;
                margin-left: 1px;
                margin-top: 8px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                background: rgba(159, 192, 255, 0.51);
                border-radius: 10px 10px 0px 0px;
                border: 1px solid #EDF0FF;
                border-bottom: none;
            }

            span:first-child {
                margin-left: 0;
            }

            .span_active {
                margin-top: 0;
                height: 48px;
                line-height: 48px;
                color: #2325FF;
                background: #EDF0FF;
            }
        }
    }

    .inv_content {
        padding: 30px 20px 20px;
        border-radius: 0 0 20px 20px;
        background: linear-gradient(153deg, #EDF0FF 0%, #CDDEFF 100%);

        .surplus {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            img {
                display: block;
                width: 26px;
                height: 26px;
                padding-top: 2px;
            }

            span {
                padding-left: 5px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
        }

        .money_list {
            display: flex;
            flex-wrap: wrap;

            .list {
                box-sizing: border-box;
                cursor: pointer;
                width: 155px;
                height: 120px;
                margin-top: 20px;
                margin-right: 20px;
                background: #FFFFFF;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .list_top {
                    color: $bColor3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .time {
                        font-size: 30px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                    }

                    .type {
                        padding-left: 9px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }

                    img {
                        display: block;
                        width: 30px;
                        height: 30px;
                        margin-top: 2px;
                        margin-left: 5px;
                    }
                }

                .list_bot {
                    padding-top: 10px;
                    color: $bColor6;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        margin-top: -2px;
                        padding-left: 7px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }
            }

            .list:nth-child(4n) {
                margin-right: 0;
            }

            .list_active {
                border: 2px solid $blueBorderColor;

                .list_top {
                    color: $blueColor1;
                }

                .list_bot {
                    color: $blueColor1;
                }
            }
        }

        /*  */
        .pay_btn {
            margin-top: 20px;
            padding: 20px;
            background: #fff;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;

            .left {
                .left_title {
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    img {
                        display: block;
                        width: 22px;
                        height: 22px;
                        padding-top: 2px;
                    }

                    .price {
                        padding-left: 5px;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                    }

                    .tip {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FF0000;
                    }
                }

                .left_tip {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;

                    span {
                        display: block;
                        padding-bottom: 5px;
                    }

                    span:last-child {
                        padding-bottom: 0;
                    }
                }

                .left_tip_four {
                    padding-bottom: 40px;
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
            }
        }
    }
}
</style>
